/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.full-width-form {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}


@import "assets/scss/material-dashboard";
@import 'ngx-toastr/toastr';
//@import '~ngx-toastr/toastr-bs4-alert';

//@import "~@ng-select/ng-select/themes/material.theme.css";
@import "@ng-select/ng-select/themes/material.theme.css";
@import "assets/fontawesome/scss/fontawesome";
@import "assets/fontawesome/scss/v4-shims";
@import "assets/fontawesome/scss/solid";
@import "assets/fontawesome/scss/brands";
@import "assets/scss/spinner";
.no-padding {
  padding: 0 !important;
}

.padding-1 {
  padding: 1px !important;
}

.custom-dialog .mat-dialog-container {
  padding: 15px 15px 0 15px !important;
}

.no-padding-top-bottom {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.no-records {
  text-align: center;
  padding: 10px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.no-margins {
  margin: 0 !important;
}

@media (min-width: 768px) {
  .md-no-margins {
    margin: 0 !important;
  }
}

.margin-left-5 {
  margin-left: 5px;
}

.padding-left-10 {
  padding-left: 10px;
}

.padding-left-0 {
  padding-left: 0 !important;
}

.padding-top-0 {
  padding-top: 0;
}

.padding-right-10 {
  padding-left: 10px;
}

.padding-right-0 {
  padding-right: 0;
}

.margin-top-auto {
  margin-top: auto;
}

.margin-top-bottom-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.flex-container-col {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.display-flex {
  display: flex;
  flex: 0 0 100%
}

.flex-take-space {
  flex-grow: 1;
}

.width-100 {
  width: 100%;
}

.cursor {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

.padding-10 {
  padding: 10px !important;
}

.margin-top-10 {
  margin-top: 10px;
}

.height-90-p {
  height: 90%;
}

.height-420 {
  height: 420px;
}

.height-10-p {
  height: 10%;
}

.no-borders {
  border: none !important;
}

.no-border-radius {
  border-radius: 0 !important;
}

.overflow-scroll {
  overflow-x: auto;
}

.profile-border {
  padding: 10px;
  border-color: gray !important;
  border-width: 1px;
  border-style: inset;
}

.new-button {
  cursor: pointer;
  text-decoration: underline;
}

.ng-select .ng-select-container {
  min-height: 46.5px !important;
}

.overflow-card-50 {
  height: 50px;
  max-height: 50px;
  overflow-y: auto;
  overflow-x: hidden;
}

.overflow-card-250 {
  height: 250px;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}

.overflow-card-300 {
  height: 300px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.pac-container {
  background-color: #FFF;
  z-index: 3000;
  position: fixed;
  display: inline-block;
  float: left;
}

agm-map {
  height: 250px !important;
  width: 100%; /* This is really important*/
}

.fa-stack[data-count]:after {
  position: absolute;
  right: 0;
  top: 1%;
  content: attr(data-count);
  font-size: 30%;
  padding: .6em;
  border-radius: 999px;
  line-height: .75em;
  color: white;
  background: rgba(255, 0, 0, .85);
  text-align: center;
  min-width: 2em;
  font-weight: bold;
}

.center-child {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.sidebar-normal {
  padding-left: 10px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.badge-azure {
  background-color: #2CA8FF;
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
